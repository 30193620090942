import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';

const API_URL = process.env.NODE_ENV === 'production' ? `https://api${process.env.NEXT_PUBLIC_ZONE_SUFFIX}/graphql` : 'https://api.test.yesloyal.com/graphql'
export const REWARDS_CDN_URL =  process.env.NODE_ENV === 'production' ? `https://assets${process.env.NEXT_PUBLIC_ZONE_SUFFIX}` : 'https://assets.test.yesloyal.com'

console.log('Constructing yesloyal apollo client for', API_URL)

const uploadLink = createUploadLink({
  uri: API_URL
});

const rewardsClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  uri: API_URL,
  cache: new InMemoryCache(),
  link: uploadLink,
});

export default rewardsClient;